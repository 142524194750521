import React, { useState, useRef, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import Card from "../shared/UIElements/Card";
import Select from "../shared/Forms/Select";
import Input from "../shared/Forms/Input";
import Textarea from "../shared/Forms/Textarea";
import Checkbox from "../shared/Forms/Checkbox";
import LoadingSpinner from "../shared/UIElements/LoadingSpinner";

import {
  marcas,
  marcasModelos,
  anos,
  combustivel,
  caixaVel,
  portas,
  equipamentos,
} from "../optionsConfig";

import "./Home.css";

import Modal from "react-bootstrap/Modal";
import storage from "../firebaseConfig";
import { ref, uploadBytes } from "firebase/storage";
import { sendEmail } from "../service";

const LOCAL_STORAGE_ENTRY = "acceptedCookies";

const eq1 = equipamentos.splice(0, 19);
const eq2 = equipamentos.splice(-18);

function Home() {
  const [acknowledgedCookies, setAknowledgedCookies] = useState(
    localStorage.getItem(LOCAL_STORAGE_ENTRY)
  );

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_ENTRY)) {
      setAknowledgedCookies(false);
    }
  }, []);

  function handleCloseCookies(action) {
    if (action === "accept") {
      localStorage.setItem(LOCAL_STORAGE_ENTRY, true);
    }
    if (action === "reject") {
      localStorage.setItem(LOCAL_STORAGE_ENTRY, false);
    }
    setAknowledgedCookies(true);
  }

  // define variables

  const [marcaOption, setMarcaOption] = useState();
  const [modelos, setModelos] = useState([]);
  const [modeloOption, setModeloOption] = useState();
  const [anoOption, setAnoOption] = useState();
  const [combustivelOption, setCombustivelOption] = useState();
  const [caixaVelOption, setCaixaVelOption] = useState();
  const [numPortasOption, setNumPortasOption] = useState();
  const [equipamentos, setEquipamentos] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileExists, setFileExists] = useState(false);

  const [modalTitle, setModalTitle] = useState("Erro no envio");
  const [modalBody, setModalBody] = useState("");
  const [modalButton, setModalButton] = useState("danger");

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sent, setSent] = useState(false);

  // let equipamentos = [];

  const kmRef = useRef("");
  const matriculaRef = useRef("");
  const valorRef = useRef("");
  const commentsRef = useRef("");
  const nameRef = useRef("");
  const emailRef = useRef("");
  const phoneRef = useRef("");
  const fileRef = useRef();

  // change handlers

  const onChangeMarca = (event) => {
    const marca = event.target.value;
    setMarcaOption(event.target.value);
    setModelos(marcasModelos[marca]);
  };

  const onChangeModelo = (event) => {
    setModeloOption(event.target.value);
  };

  const onChangeAno = (event) => {
    setAnoOption(event.target.value);
  };

  const onChangeCombustivel = (event) => {
    setCombustivelOption(event.target.value);
  };

  const onChangeCaixaVel = (event) => {
    setCaixaVelOption(event.target.value);
  };

  const onChangeNumPortas = (event) => {
    setNumPortasOption(event.target.value);
  };

  const onChangeFile = (event) => {
    const totalSizeOfAllFiles = Array.from(event.target.files).reduce(
      (acc, file) => acc + file.size,
      0
    );

    const oneGbInBytes = 1000000000;
    if (totalSizeOfAllFiles > oneGbInBytes) {
      fileRef.current.value = "";
      setModalBody("O tamanho total dos ficheiros não pode exceder 1GB!");
      handleShow();
    } else {
      setUploadedFiles(event.target.files);
      setFileExists(true);
    }
  };

  const onChangeEquipamentos = (event) => {
    const { id, checked } = event.target;
    if (checked && !equipamentos.includes(id)) {
      //equipamentos.push(id);
      setEquipamentos((listaEquipamentos) => [...listaEquipamentos, id]);
    } else {
      // equipamentos = equipamentos.filter((e) => e !== id);
      setEquipamentos((equipamentos) => equipamentos.filter((e) => e !== id));
    }
  };

  const handleClose = () => {
    setShow(false);
    if (sent) {
      window.location.reload(false);
    }
  };
  const handleShow = () => setShow(true);

  const submitHandler = async (event) => {
    event.preventDefault();

    //check validity of fields

    const kms = kmRef.current.value.replaceAll(".", "");

    if (
      marcaOption === undefined ||
      marcaOption === "Selecione a marca do automóvel"
    ) {
      setModalBody("Por favor, selecione a marca do automóvel!");
      handleShow();
      return;
    }
    if (
      modeloOption === undefined ||
      modeloOption === "Selecione o modelo do automóvel"
    ) {
      setModalBody("Por favor, selecione o modelo do automóvel!");
      handleShow();
      return;
    }
    if (
      anoOption === undefined ||
      anoOption === "Selecione o ano do automóvel"
    ) {
      setModalBody("Por favor, selecione o ano do automóvel!");
      handleShow();
      return;
    }
    if (
      combustivelOption === undefined ||
      combustivelOption === "Selecione o tipo de combustível"
    ) {
      setModalBody("Por favor, selecione o tipo de combustível do automóvel!");
      handleShow();
      return;
    }
    if (
      caixaVelOption === undefined ||
      caixaVelOption === "Selecione a caixa de velocidades"
    ) {
      setModalBody(
        "Por favor, selecione o tipo de caixa de velocidades do automóvel!"
      );
      handleShow();
      return;
    }
    if (
      numPortasOption === undefined ||
      numPortasOption === "Selecione o número de portas"
    ) {
      setModalBody("Por favor, selecione o número de portas do automóvel!");
      handleShow();
      return;
    }
    if (kms.trim() === "") {
      setModalBody("Por favor, insira o número de quilometros do automóvel!");
      handleShow();
      return;
    }
    if (parseInt(kms) > 200000) {
      setModalBody(
        "Desculpe, mas no momento não aceitamos veículos com mais de 200.000 km!"
      );
      handleShow();
      return;
    }
    if (nameRef.current.value.trim() === "") {
      setModalBody("Por favor, insira o seu nome");
      handleShow();
      return;
    }
    if (emailRef.current.value.trim() === "") {
      setModalBody("Por favor, insira o seu endereço de e-mail");
      handleShow();
      return;
    }
    if (phoneRef.current.value.trim() === "") {
      setModalBody("Por favor, insira o seu número de telefone");
      handleShow();
      return;
    }

    //set params and send email

    const params = {
      marca: marcaOption,
      modelo: modeloOption,
      ano: anoOption,
      combustivel: combustivelOption,
      caixaVel: caixaVelOption,
      numPortas: numPortasOption,
      kms: kmRef.current.value,
      matricula:
        matriculaRef.current.value.trim() === ""
          ? "Não especificado"
          : matriculaRef.current.value,
      valor:
        valorRef.current.value.trim() === ""
          ? "Não especificado"
          : valorRef.current.value + " euros",
      comentarios:
        commentsRef.current.value.trim() === ""
          ? "Não há comentários"
          : commentsRef.current.value,
      equipamentos:
        equipamentos.length === 0 ? "Não especificado" : equipamentos,
      nome: nameRef.current.value,
      email: emailRef.current.value,
      telefone: phoneRef.current.value,
      fotos:
        uploadedFiles.length === 0
          ? "Não há fotos"
          : `${marcaOption}-${modeloOption}-${anoOption}-${matriculaRef.current.value}`,
    };

    setModalTitle("A enviar dados do veículo");
    setModalBody("");
    handleShow();
    setIsLoading(true);

    const result = await sendEmail(params);

    // if email sent successfully, upload pictures to firebase storage
    if (result.status === 200) {
      setSent(true);
      setIsLoading(false);
      setModalTitle("O primeiro passo está dado");
      setModalBody(
        "Entraremos em contacto consigo o mais rápido possivel, obrigado!"
      );
      setModalButton("success");
      pushDataLayer(params);
      // upload pictures to firebase storage
      if (uploadedFiles.length > 0) {
        for (const file of uploadedFiles) {
          const imageRef = ref(
            storage,
            `images/${marcaOption}-${modeloOption}-${anoOption}-${matriculaRef.current.value}/${file.name}`
          );
          await uploadBytes(imageRef, file);
        }
      }
    } else {
      setSent(false);
      setIsLoading(false);
      setModalBody("Erro no envio do formulário, por favor tente outra vez!");
    }
  };

  const pushDataLayer = (params) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "c_event",
      dl_ga4_event: "submission_form",
      dl_form: "avaliar",
      dl_modelo: `${params.modelo}`,
      dl_marca: `${params.marca}`,
      dl_fotos: `${fileExists}`,
      dl_kms: `${params.kms}`,
      dl_ano: `${params.ano}`,
      dl_combustivel: `${params.combustivel}`,
      dl_tipocaixa: `${params.caixaVel}`,
      dl_preco: `${params.valor}`,
      dl_portas: `${params.numPortas}`,
      dl_matricula: `${params.matricula}`,
      dl_comentarios: `${
        params.comentarios === "Não há comentários" ? false : true
      }`,
      dl_equipamento: `${params.equipamentos}`,
    });
  };

  return (
    <React.Fragment>
      <Modal
        show={!acknowledgedCookies}
        onHide={() => handleCloseCookies("ignore")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Consentimento de cookie</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          Este site usa cookies que ajudam as funções do site e rastreiam como
          você interage com ele, para que possamos fornecer a você uma
          experiência de usuário aprimorada e personalizada. Só usaremos os
          cookies se você consentir clicando em Aceitar.
        </Modal.Body>
        <Modal.Footer>
          <a href="/outros#cookies">Política de cookies</a>
          <Button variant="danger" onClick={() => handleCloseCookies("reject")}>
            Rejeitar todas
          </Button>
          <Button
            variant="success"
            onClick={() => handleCloseCookies("accept")}
          >
            Aceitar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="main-content">
        <h1 className="title">Venda o seu Carro em 3 Tempos:</h1>
        <img src="/images/banner.png" alt="banner" />
        <Card className="main-content-form">
          <h2>Preencha o formulário para começar o processo</h2>
          <h2>
            Ou contacte-nos via WhatsApp{" "}
            <a
              className="icon"
              href="https://wa.me/351967323907?text="
              target="ablank"
            >
              {" "}
              <img alt="icone whatsapp" src="/images/whatsapp.svg" />
            </a>{" "}
          </h2>
          <h5>Informação do veículo:</h5>
          <hr />
          <Form
            noValidate
            onSubmit={submitHandler}
            encType="multipart/form-data"
            method="post"
          >
            <div className="form">
              <div className="group">
                <Select
                  onChange={onChangeMarca}
                  value={marcaOption}
                  label="Marca: *"
                  title="Selecione a marca do automóvel"
                  options={marcas}
                  name="marca"
                />
                <Select
                  onChange={onChangeModelo}
                  label="Modelo: *"
                  title="Selecione o modelo do automóvel"
                  options={modelos}
                  name="modelo"
                />
                <Select
                  onChange={onChangeAno}
                  label="Ano: *"
                  title="Selecione o ano do automóvel"
                  options={anos}
                  name="ano"
                />
              </div>
              <div className="group">
                <Select
                  onChange={onChangeCombustivel}
                  label="Combustível: *"
                  title="Selecione o tipo de combustível"
                  options={combustivel}
                  name="combustivel"
                />
                <Select
                  onChange={onChangeCaixaVel}
                  label="Caixa de velocidade: *"
                  title="Selecione a caixa de velocidades"
                  options={caixaVel}
                  name="caixaVel"
                />
                <Select
                  onChange={onChangeNumPortas}
                  label="Número de portas: *"
                  title="Selecione o número de portas"
                  options={portas}
                  name="numPortas"
                />
              </div>
              <div className="group-text">
                <Input
                  inputRef={kmRef}
                  id="kms"
                  type="number"
                  label="Quilometragem (Max 200.000km): *"
                  placeholder="MAX 200.000KM"
                  name="kms"
                />
                <Input
                  inputRef={matriculaRef}
                  type="text"
                  label="Matricula"
                  placeholder="Matricula"
                  name="matricula"
                />
                <Input
                  inputRef={valorRef}
                  type="number"
                  label="Valor pretendido (€)"
                  placeholder="Valor"
                  name="valor"
                />
              </div>
              <div className="group-picture-km">
                <Input
                  inputRef={fileRef}
                  onChange={onChangeFile}
                  id="fotos"
                  type="file"
                  label="Fotos: (máximo 1GB)"
                  name="fotos"
                  multiple
                />
              </div>
              <Textarea
                inputRef={commentsRef}
                id="comentarios"
                label="Comentários:"
                placeholder="Principais características e defeitos"
                name="comentarios"
              />
              <div className="group-equipamentos">
                <Checkbox
                  id="eq1"
                  label="Equipamentos:"
                  group="equipamentos"
                  options={eq1}
                  changeHandler={onChangeEquipamentos}
                />
                <Checkbox
                  id="eq2"
                  group="equipamentos"
                  options={eq2}
                  changeHandler={onChangeEquipamentos}
                />
              </div>
              <h5>Informação Pessoal:</h5>
              <hr />
              <div className="group-personal">
                <Input
                  inputRef={nameRef}
                  id="nome"
                  type="text"
                  label="Nome: *"
                  placeholder="O seu nome"
                  feedback="Por favor, introduza o seu nome"
                  name="nome"
                />
                <Input
                  inputRef={emailRef}
                  id="email"
                  type="text"
                  label="E-mail: *"
                  placeholder="Endereço de e-mail"
                  feedback="Por favor, introduza o seu e-mail"
                  name="email"
                />
                <Input
                  inputRef={phoneRef}
                  id="telefone"
                  type="text"
                  label="Telefone: *"
                  placeholder="Número de telefone"
                  feedback="Introduza o seu telefone"
                  name="telefone"
                />
              </div>
            </div>
            <Button className="btn" type="submit" variant="success">
              Submeter
            </Button>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body
                className={isLoading ? "modal-loading" : "modal-body"}
              >
                {isLoading && <LoadingSpinner />}
                {modalBody}
              </Modal.Body>
              <Modal.Footer>
                <Button variant={modalButton} onClick={handleClose}>
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default Home;
